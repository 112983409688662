<template>
    <v-main>
        <v-img
            :key="src"
            :src="require(`@/assets/${src}`)"
            
            gradient="to top, #00000080, #00000080"
            min-height="100vh"
            :height="$vuetify.breakpoint.mdAndUp ? '100vh' : undefined"
        >
            <div
                :class="[$vuetify.breakpoint.mdAndUp && 'fill-height']"
                class="d-block d-md-flex"
            >
                <page-app-bar />

                <router-view />

                <page-footer />
            </div>
        </v-img>
    </v-main>
</template>

<script>
export default {
    name: 'PageView',
    components: {
        PageAppBar: () => import('@/components/page/AppBar'),
        PageFooter: () => import('@/components/page/Footer')
    },
    data: () => ({
      src: 'birdslife_background.svg'
    })
}
</script>
